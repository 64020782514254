export function getInitialState() {
    return {
        savedResults: [],
        searchText: "",
        selectedFilters: {
            startDate: "",
            endDate: "",
            norms: "",
            processType: "",
            providenceType: "",
            category: "",
            principalDescriptors:"",
            secondDescriptors:"",
            matter:"",
            font:"",
            content:"",
            rol: "",
            idType: "",
            idNumber: "",
            name: "",
            tem:"",
            expediente: "",
        },
        filtersListValues: {
            processesList: null,
            providencesList: null,
            categoriesList: null,
            descriptorsList: null,
            restrictionsList: null,
            rolsList: null,
            identificationsList: null,
            fontsList: null,
            mattersList: null,
            temList: null,
            principalDescriptorsList: null,
            secondDescriptorsList: null,
            contentList: null,
        },
        showFooter: true,
        searchResults: null,
        dateResults: null,
        searchDocumentText: "",
        loadingResults: false,
        searchTrigger: false,
        sortType: [],
        resultsViewPage: 1,
    }
}

export function setInputSearchText(state, action) {
    const { input_text } = action;

    return {
        ...state,
        searchText: input_text,
    }
}

export function cleanInputSearchText(state, action) {
    return {
        ...state,
        searchText: "",
    }
}

export function updateFilters(state, action) {
    const { filters } = action;

    return {
        ...state,
        selectedFilters: filters,
    }
}

export function setFiltersListValues(state, action) {
    const { options } = action;

    return {
        ...state,
        filtersListValues: options
    }
}

export function toggleFooter(state) {
    return {
        ...state,
        showFooter: !state.showFooter,
    }
}

export function saveSearchResults(state, action) {
    const { results } = action;
    return {
        ...state,
        searchResults: results,
    }
}

export function saveDateResults(state, action) {
    const { dateResults } = action;
    return {
        ...state,
        dateResults: dateResults,
    }
}

export function saveResults(state, action) {
    const { result } = action;
    if (state.savedResults.some((savedResult) => savedResult._id === result._id)) {
        return {
            ...state
        }
    } else {
        return {
            ...state,
            savedResults: [...state.savedResults, result]
        }
    }

}

export function deleteResult(state, action) {
    const resultId = action.savedResult._id;


    //state.savedResults.splice(state.savedResults.indexOf(result))
    return {
        ...state,
        savedResults: state.savedResults.filter((savedResult) => savedResult._id !== resultId)
    };
}

export function setDocumentSearchText(state, action) {
    const { documentSearchText } = action;

    return {
        ...state,
        searchDocumentText: documentSearchText
    }
}

export function setLoadingResults(state) {
    //  console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: !state.loadingResults
    }
}

export function setLoadingResultsFalse(state) {
    // console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: false
    }
}

export function setLoadingResultsTrue(state) {
    // console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: true
    }
}

export function setSearchTriggerTrue(state) {
    //  console.log(state);
    return {
        ...state,
        searchTrigger: true
    }
}

export function setSearchTriggerFalse(state) {
    // console.log(state);
    return {
        ...state,
        searchTrigger: false
    }
}

export function setSortType(state, action) {
    return {
        ...state,
        sortType: action.sortType
    }
}

export function setResultsViewPage(state, action) {
    return {
        ...state,
        resultsViewPage: action.page
    }
}