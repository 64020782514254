// import React from 'react'
// import { Redirect, Switch, HashRouter as Router, Route } from 'react-router-dom'
// import HomeView from '../views/HomeView'
// import ResultsView from '../views/ResultsView';
// import SavedView from '../views/SavedView';

// export default function Routes() {
//     return (
//         <Router>
//             <Switch>
//                 <Route exact path={`/`} component={HomeView} />
//                 <Route path={`/results`} component={ResultsView} />
//                 <Route path={`/saved`} component={SavedView} />
//                 <Route path={`/*`}>
//                     <Redirect to={`/`} />
//                 </Route>
//             </Switch>
//         </Router>
//     )
// }

import React, { lazy, Suspense } from 'react'
import { Redirect, Switch, HashRouter as Router, Route } from 'react-router-dom'
import LoadingIndicator from '../components/LoadingIndicator'


const HomeView = lazy(() => import('../views/HomeView'))
const ResultsView = lazy(() => import('../views/ResultsView'))
const SavedView = lazy(() => import('../views/SavedView'))
const JsonViewer = lazy(() => import('../components/JsonViewer'))

export default function Routes() {
    return (
        <Router>
            <Suspense fallback={<div style={{ minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><LoadingIndicator loadingText={"Cargando"} /></div>}>
                <Switch>
                    <Route exact path={`/`} component={HomeView} />
                    <Route path={`/results`} component={ResultsView} />
                    <Route path={`/saved`} component={SavedView} />
                    <Route exact path="/jsonviewer/:id" component={JsonViewer} />
                    <Route path={`/*`}>
                        <Redirect to={`/`} />
                    </Route>
                   
                </Switch>
            </Suspense>
        </Router>
    )
}
