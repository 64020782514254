import React from 'react'
import './footer.scss'
import Logo_fb from '../../../assets/icons/fb.png';
import Logo_ig from '../../../assets/icons/ig.png';
import Logo_tw from '../../../assets/icons/tw.png';
import Logo_contacto from '../../../assets/icons/contacto.png';
// import { useSelector } from 'react-redux';
// import { getFooterStatus } from '../../store/selectors';

export default function Footer(props) {

    // const footerState = useSelector(getFooterStatus);
    //<span className="footer__value link" onClick={() => window.open(`mailto:notificacionesjudiciales@supersociedades.gov.co`)} >notificacionesjud@sic.gov.co</span>
    return (
        <>
            <div className="footer">
                <div className="footer__info">
                    {/* <span className="logos_gov">
                        <img alt="contact" className="logogov" src={Logo_gov}></img>
                        <img alt="contact" className="logopais" src={Logo_pais}></img>
                    </span> */}
                    <span className="footer__value no-margin-bottom">
                        <label className="bg_logo" onClick={() => window.open(`https://twitter.com/SSociedades`)} alt="contact"><img alt="contact" className="logos_redes" style={{marginLeft:'14px',marginTop:'14px'}}src={Logo_tw}></img></label> 
                        <label className="bg_logo" onClick={() => window.open(`https://www.instagram.com/ssociedades/`)} alt="contact"><img alt="contact" className="logos_redes" style={{marginLeft:'15px',marginTop:'14px'}} src={Logo_ig}></img></label> 
                        <label className="bg_logo" onClick={() => window.open(`https://www.facebook.com/SSociedades/`)} href="" alt="contact"><img alt="contact" className="logos_redes" style={{marginLeft:'18px',marginTop:'12px'}} src={Logo_fb}></img></label> {/* <span className="redes_texto">Facebook</span> */}
                    </span>
                </div>
                <div className="vl"></div>
                <div className="footer__info col3">
                    <span className="footer__title">Superintendencia de Sociedades</span>
                    <span className="footer__label">Dirección: Avenida El Dorado No. 51-80 <br/> Bogotá - Colombia, Código Postal 111321</span>
                    <span className="footer__label">Horario de atención:<br/> Lunes a viernes de 8:00 a.m. a 5:00 p.m.</span>
                    
                </div>
                <div className="vl"></div>
                <div className="footer__info logos">
                
                        <span className="footer__value">
                        <img alt="contact" className="logos_redes" src={Logo_contacto}></img><span className="footer__title logos-title">&nbsp; Contacto</span>
                        </span>
                        <span className="footer__label">Línea única de atención al usuario: <br/>(+57+1) 2201000</span>
                        <span className="footer__label">Línea gratuita nacional de atención al ciudadano: <br/>01-8000-114319</span>
                        <span className="footer__label">Centro de Fax: (+57+1) 3245000</span>
                        <span className="footer__label">notificacionesjudiciales@supersociedades.gov.co </span>
                        <span className="footer__label">webmaster@supersociedades.gov.co </span>
                        
                        <div className="links">
                            <span className="footer__value link" onClick={() => window.open(`https://www.supersociedades.gov.co/Paginas/Mapa-Sitio.aspx`)}>MAPA DEL SITIO</span>
                            <span className="footer__label">&nbsp; &nbsp; &nbsp; &nbsp;</span>
                            <span className="footer__value link" onClick={() => window.open(`https://www.supersociedades.gov.co/Paginas/Condiciones-de-uso-privacidad.aspx`)}>POLÍTICAS</span>
                        </div>
                        
                </div>
            </div>
        </>
    )
}
