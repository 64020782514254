import React from 'react'
import './header.scss'
import Logo_header from '../../../assets/icons/GOV.CO_Logo-whiteGOV.CO_Logo-white.png';
import Logo_marcapais from '../../../assets/icons/marcapais.png';

export default function Header() {
    return (
        <div className="header">
            <span className="header__brand" >
                <img className='logo_brand' alt="Logo" src={Logo_header}></img>
            </span>
            {/* <button className="btn_login" onClick={() => window.open(URL_VISOR_RELATORIAS)} >Iniciar sesión</button> */}
            <span className="header__link" onClick={() => window.open(`https://www.gov.co/home`)}>Ir a Gov.co <img alt="Logo" className="marcapais" src={Logo_marcapais}></img></span>
        </div>
    )
}
